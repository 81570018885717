import React, { useCallback } from 'react';
import { Button, Placeholder } from 'rsuite';

import { EmptyPlaceholder, DasboardLabel } from '../../components';

import './dashboard-sidebar-list.scss';

const DefaultComponentRow = obj => <span>obj.id</span>;


const ListRow = ({ buttons = [], data, children, onClick, onClickRow, disabled }) => {
  const handleClick = useCallback(
    (evt) => {
      evt.preventDefault();
      const button = evt.target.attributes['data-button'];
      onClick(button.nodeValue, data);
    },
    [data, onClick]
  );
  const handleClickRow = useCallback(
    () => {
      onClickRow(data);
    },
    [data, onClickRow]
  );

  return (
    <div className="lf-list-row">
      <div className="left" onClick={handleClickRow}>
        {children}
      </div>
      <div className="right">
        {buttons.map(button => (
          <Button
            key={button.button}
            data-button={button.button}
            size="xs"
            appearance="ghost"
            disabled={disabled}
            onClick={handleClick}
          >{button.label}</Button>
        ))}
      </div>
    </div>
  );
};


const DashboardSidebarList = ({
  title,
  description,
  data = [],
  buttons = [],
  onButton = () => { },
  createButton,
  onClickRow = () => { },
  onCreateButton = () => { },
  ComponentRow = DefaultComponentRow,
  loading = false,
  disabled = false,
  emptyPlaceholder = `There's nothing here`
}) => {
  return (
    <div className="lf-dashboard-sidebar-list">
      <DasboardLabel
        description={description}
        button={createButton}
        onButton={onCreateButton}
        disabled={disabled}
      >
        {title}
      </DasboardLabel>
      {!loading && Array.isArray(data) && data.length !== 0 && (
        <div className="data">
          {(data || []).map(obj => (
            <ListRow
              key={obj.id}
              buttons={buttons}
              data={obj}
              onClick={onButton}
              onClickRow={onClickRow}
              disabled={disabled}
            >
              <ComponentRow data={obj} />
            </ListRow>
          ))}
        </div>
      )}
      {!loading && Array.isArray(data) && data.length === 0 && (
        <EmptyPlaceholder>
          {emptyPlaceholder}
        </EmptyPlaceholder>
      )}
      {loading && (
        <Placeholder.Paragraph rowSpacing={10} rows={3} active />
      )}
    </div>
  );
};

export { DashboardSidebarList };
