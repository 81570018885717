import { definePlugin } from '../../code-plug';

import { DemoTag } from './views/demo-tag';

definePlugin('builder-demo-tag', ({ registerView }) => {

  registerView(
    'form-builder-page',
    DemoTag
  );
});
