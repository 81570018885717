import React from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { Toolbar } from './toolbar';

const BuilderToolbar = () => {
  const form = useFormBuilderStore(state => state.form);

  return (
    <Toolbar
      form={form}
    />
  );
};

export { BuilderToolbar };