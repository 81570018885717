import React, { useCallback, useState } from 'react';
import 'lodash.product';
import _ from 'lodash';
import { Button, Table, Loader } from 'rsuite';

import { EmptyPlaceholder, IsoLang, LocalesList } from '../../../components';

import { ActionCell, EditableCell } from '../components';
import './complete-translations.scss';

const { Column, HeaderCell, Cell } = Table;

const prepareBatch = (groceryList, sourceLocale, targetLocale) => {
  return groceryList
    .filter(item => item.targetLocale === targetLocale && item.sourceLocale === sourceLocale)
    .filter(item => _.isEmpty(item.translation))
    .reduce(
      (acc, value) => [...acc, value.source],
      []
    );
};

const translateBatch = async (batch, sourceLocale, targetLocale) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      from: sourceLocale,
      to: targetLocale,
      e: '',
      q: batch
    })
  };

  const res = await fetch('/api/translate', options);
  const json = await res.json();

  return json;
}

const fillGroceryList = (groceryList, sourceLocale, targetLocale, batch, translatedBatch) => {
  return groceryList
    .map(item => {
      // skip already translated and those not matching source and target locale
      if (item.translation || item.sourceLocale !== sourceLocale || item.targetLocale !== targetLocale) {
        return item;
      };
      const idx = batch.indexOf(item.source);
      if (idx !== -1) {
        return {
          ...item,
          translation: translatedBatch[idx]
        };
      }
      return item;
    });
};

const EmptyGroceryList = () => {
  return (
    <EmptyPlaceholder>
      <span>
        <b>All tokens are translated</b><br />
        If you think some translations are still missing check the form configuration for locales
        that should be supported.<br />
        Also check that all strings that should be translated in the field props are assigned at least
        to a locale (strings <em>"no tx"</em> will not be translated).
      </span>
    </EmptyPlaceholder>
  )
};

const CompleteTranslations = ({ value, onChange }) => {
  const [groceryList, setGroceryList] = useState(value.groceryList);
  const [translating, setTranslating] = useState(false);

  const handleClick = useCallback(
    async () => {
      setTranslating(true);

      // set to translating
      onChange({
        ...value,
        translating: true
      });
      // get list of translations
      let newGroceryList = [...groceryList];
      // split into batches
      const sourceLocales = _.uniq(groceryList.map(item => item.sourceLocale));
      const targetLocales = _.uniq(groceryList.map(item => item.targetLocale));
      const cartesian = _.product(sourceLocales, targetLocales)

      let idx = 0;
      for (idx = 0; idx < cartesian.length; idx++) {
        const [sourceLocale, targetLocale] = cartesian[idx];
        // prepare a batch of translations
        const batch = prepareBatch(groceryList, sourceLocale, targetLocale);

        try {
          const translatedBatch = await translateBatch(batch, sourceLocale, targetLocale);
          newGroceryList = fillGroceryList(newGroceryList, sourceLocale, targetLocale, batch, translatedBatch);
          setGroceryList(newGroceryList);
        } catch(e) {
          console.error(`[LetsForm] error translating batch from ${sourceLocale} - ${targetLocale} `, batch);
        }
      }

      setTranslating(false);
      // propagate to modal state
      onChange({
        ...value,
        translating: false,
        groceryList: newGroceryList
      });
    },
    [groceryList, onChange, value]
  );

  const handleEditState = id => {
    const nextData = Object.assign([], groceryList);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    setGroceryList(nextData);
  };

  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], groceryList);
    nextData.find(item => item.id === id)[key] = value;
    setGroceryList(nextData);
  };

  const tokensCount = groceryList.filter(item => _.isEmpty(item.translation)).length;
  const missingLocales = _.uniq(groceryList
    .filter(item => _.isEmpty(item.translation))
    .map(item => item.targetLocale));

  return (
    <div className="lf-manage-translations">
      {tokensCount !== 0 && (
        <div className="header">
          <div className="left">
            There are <b>{tokensCount}</b> language tokens to be translated in&nbsp;
            <LocalesList locales={missingLocales}/>

          </div>
          {translating && (
            <div className="loader">
              <Loader />
            </div>
          )}
          <div className="right">
            <Button
              appearance="primary"
              disabled={translating}
              onClick={handleClick}
            >Start translation</Button>

          </div>
        </div>
      )}
      <Table
        height={400}
        data={groceryList}
        renderEmpty={EmptyGroceryList}
      >
        <Column width={150}>
          <HeaderCell>Field name</HeaderCell>
          <Cell>
            {rowData => <b>{rowData.name}</b>}
          </Cell>
        </Column>
        <Column width={100} fullText>
          <HeaderCell>Field property</HeaderCell>
          <Cell>
            {rowData => {
              let prop = '';
              if (rowData.framework != null) {
                prop += `['${rowData.framework}'].`;
              }
              prop += rowData.key;
              if (rowData.idx != null) {
                prop += `[${rowData.idx}]`;
              }
              if (rowData.subKey) {
                prop += `.${rowData.subKey}`;
              }
              return <em>{prop}</em>;
            }}
           </Cell>
        </Column>
        <Column width={60} fixed>
          <HeaderCell>Locale</HeaderCell>
          <Cell>
            {rowData => <IsoLang locale={rowData.targetLocale} />}
           </Cell>
        </Column>
        <Column width={150} fixed fullText>
          <HeaderCell>Source</HeaderCell>
          <Cell>
            {rowData => <span className="source-cell">{rowData.source} <span className="locale">{rowData.sourceLocale}</span></span>}
           </Cell>
        </Column>
        <Column width={340} resizable>
          <HeaderCell>Translation</HeaderCell>
          <EditableCell
            dataKey="translation"
            disabled={translating}
            onChange={handleChange}
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>...</HeaderCell>
          <ActionCell disabled={translating} dataKey="id" onClick={handleEditState} />
        </Column>
      </Table>
    </div>
  );
};

export { CompleteTranslations };
