import React, { useCallback } from 'react';


import { useListenTo } from '../../../code-plug';
import { useModal } from '../../../components';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { collectNames, fillIds } from '../../../components/lets-form';
import { useNotification } from '../../../hooks';

// TODO move these
import { mergeTemplate } from '../helpers/merge-template';
import { canMergeForms, getConflictingFields } from '../helpers/can-merge-forms';
//import { SelectTemplate } from './select-template';
import { InstallFormModal } from './install-form-modal';
import { ModalConfirmOverwrite } from './modal-confirm-overwrite';


const InstallTemplateCore = () => {
  const setEditMode = useFormBuilderStore(state => state.setEditMode);
  const setGeneration = useFormBuilderStore(state => state.setGeneration);
  const setForm = useFormBuilderStore(state => state.setForm);
  const form = useFormBuilderStore(state => state.form);
  const notification = useNotification();


  const publishEventAttrs = useListenTo({

    'installTemplate': ({ template, fieldId, target, subtarget }) => {

      console.log('arriva qua da dai cazzo')
      //alert('dai cazzo!');
      handleImportTemplate(template, fieldId, target, subtarget);
    }
  });

  const { open: openInstallForm, close: closeInstallForm } = useModal({
    view: InstallFormModal,
    size: 'sm',
    name: 'installFormModal',
    title: 'Import template',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const { open: openConfirmOverwrite, close: closeConfirmOverwrite } = useModal({
    view: ModalConfirmOverwrite,
    size: 'sm',
    name: 'confirmOverwrite',
    align: 'center',
    labelCancel: 'Cancel',
    labelSubmit: 'Ok, overwrite form',
    title: 'Overwrite form'
  });


  const handleImportTemplate = useCallback(
    async (template, id, target, subtarget) => {
      // hide he selector
      //setShowDrawer(false);
      setEditMode(true);
      // if install params, then ask
      let configuredTemplate = template;
      if (template.installForm) {
        configuredTemplate = await openInstallForm({
          template,
          existingFields: collectNames(form)
        });
        await closeInstallForm();
        if (!configuredTemplate) {
          return;
        }
      }
      // finally parse parse the template
      let formFromTemplate;
      try {
        formFromTemplate = JSON.parse(configuredTemplate.template);
      } catch(e) {
        console.error(`[LetsForm] error importing template id: ${configuredTemplate.id}`);
        return;
      }
      // template can have field names clash
      if (!canMergeForms(form, formFromTemplate)) {
        const confirmOverwrite = await openConfirmOverwrite({
          fieldNames: getConflictingFields(form, formFromTemplate).join(', ')
        });
        closeConfirmOverwrite();

        if (confirmOverwrite) {
          setForm({
            ...formFromTemplate,
            fields: fillIds(formFromTemplate.fields)
          });
          notification.success(
            'Import template',
            <div>
              Template imported successfully!
            </div>
          );
        }
        return form;
      } else {
        setForm(form => mergeTemplate(form, formFromTemplate, id, target, subtarget));
        notification.success(
          'Import template',
          <div>
            Template imported successfully and merged with the current form.
          </div>
        );
        setGeneration(generation => generation + 1);
      }
    },
    [setForm, setGeneration, closeConfirmOverwrite, setEditMode, closeInstallForm, form, notification, openConfirmOverwrite, openInstallForm]
  );

  return (
    <span {...publishEventAttrs}></span>
  );
};

export { InstallTemplateCore };
