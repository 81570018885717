import { definePlugin } from '../../code-plug';

import { LetsFormLogo } from './views/lets-form-logo';

definePlugin('builder-logo', ({ registerView }, params) => {

  registerView(
    'form-builder-canvas',
    LetsFormLogo,
    { ...params }
  );
});
