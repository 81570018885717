import _ from 'lodash';

import Manifests from '../manifest';

/**
 * getUnavailableComponents
 * Given the framework, return the list of un-available components from those registered
 * @param {String} framework
 * @return {[String]}
 */
const getUnavailableComponents = framework => {
  const unavailableComponents = Object.keys(Manifests)
    .reduce(
      (acc, componentName) => {
        const component = Manifests[componentName];
        if (
          componentName === 'forms' ||
          (_.isArray(component.frameworks) && !_.isEmpty(component.frameworks) && component.frameworks[0] === '*') ||
          (_.isArray(component.frameworks) && component.frameworks.includes(framework))
        ) {
          return acc;
        }
        return [...acc, componentName];
      },
      []
    );
  return unavailableComponents;
};

export { getUnavailableComponents };
