const RAW_ESMSH_PACKAGES = ['lets-form'];

// Only used for lets-form, deprecated
export const createCDNUrl = name => {
  return `https://cdn.jsdelivr.net/npm/lets-form${name}`;
};

export const generateCDNUrl = (packageName, opts) => {
  const options = Object.assign({
    type: 'esm.sh', // ems.sh, unpkg.com
    version: null,
    path: null,
    external: null
  }, opts);

  if (options.type === 'unpkg.com') {
    return 'https://unpkg.com' +
      `/${packageName}` +
      (options.version ? `@${options.version.replace('^', '')}` : '') +
      (options.folder ? `/${options.folder}` : '');
  } else if (options.type === 'esm.sh') {
    return 'https://' +
      (RAW_ESMSH_PACKAGES.includes(packageName) ? 'raw.esm.sh' : 'esm.sh') +
      `/${packageName}` +
      (options.version ? `@${options.version.replace('^', '')}` : '') +
      (options.path ? `${options.path}` : '') +
      (options.external ? `?external=${options.external}` : '');
  }
};
