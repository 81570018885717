import React, { useCallback } from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { generateFakeData } from '../helpers/generate-fake-data';
import { usePostMessage } from '../../../hooks'

import { TestToolbar } from './test-toolbar';


const TestToolbarContainer = ({
  top,
  buttons
}) => {
  const form = useFormBuilderStore(state => state.form);
  const editMode = useFormBuilderStore(state => state.editMode);
  const framework = useFormBuilderStore(state => state.framework);
  const plaintextMode = useFormBuilderStore(state => state.plaintextMode);
  const generation = useFormBuilderStore(state => state.generation);
  const { postMessage } = usePostMessage({
    ns: 'lf'
  });

  const {
    setEditMode,
    setPlaintextMode,
    setSidebar,
    setField,
    setGeneration,
    setJsErrors,
    setDefaultValues,
    setFormValue
  } = useFormBuilderStore();

  const handleRegenerate = useCallback(
    () => {
      setJsErrors(null);
      // trigger redesign
      setGeneration(generation + 1);
    },
    [generation, setGeneration, setJsErrors]
  );

  const handleSaveForm = useCallback(
    () => {
      postMessage({
        type: 'onSave',
        form
      });
    },
    [form, postMessage]
  );

  return (
    <TestToolbar
      onRegenerate={handleRegenerate}
      buttons={buttons}
      editMode={editMode}
      plaintextMode={plaintextMode}
      onPlaintextMode={plaintextMode => {
        if (!plaintextMode) {
          setPlaintextMode(false);
          setEditMode(true);
          setSidebar('form');
        } else {
          setPlaintextMode(true);
          setEditMode(true);
          setField(null);
          setSidebar('debug');
        }
      }}
      onEditMode={editMode => {
        if (editMode) {
          setEditMode(true);
          setPlaintextMode(false);
          setSidebar('form');
        } else {
          setEditMode(false);
          setPlaintextMode(false);
          setField(null);
          setSidebar('debug');
        }
      }}
      onTestData={() => {
        const fakeData = generateFakeData(form, framework);
        setFormValue(fakeData);
        setDefaultValues(fakeData);
        setJsErrors(null);
        // trigger redesign
        setGeneration(generation + 1);
      }}
      onSaveForm={handleSaveForm}
      style={top != null ? { top } : null}
      placement={top != null && !isNaN(parseInt(top, 10)) && parseInt(top, 10) < 50 ? 'bottomStart' : 'topStart'}
    />
  );
};

export { TestToolbarContainer };
