import React from 'react';
import { FaWpforms } from 'react-icons/fa';


import Manifests from '../../manifest';

const FrameworkIcon = ({ size = 16, framework }) => {
  console.log('framework', framework)
  const manifest = Manifests.forms[framework];
  if (!manifest) {
    return <></>;
  }
  const Icon = manifest.icon ?? FaWpforms;

  return (
    <Icon id="form-editor-icon" width={size} height={size}/>
  );
};

export { FrameworkIcon }