import React, { useCallback } from 'react';
import _ from 'lodash';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { FormEditor } from './form-editor';

const EditFormPanelContainer = ({ showFrameworkLogo }) => {
  const form = useFormBuilderStore(state => state.form);
  const setForm = useFormBuilderStore(state => state.setForm);
  const framework = useFormBuilderStore(state => state.framework);
  const locale = useFormBuilderStore(state => state.locale);
  const setLocale = useFormBuilderStore(state => state.setLocale);
  const generation = useFormBuilderStore(state => state.generation);
  const setJsErrors = useFormBuilderStore(state => state.setJsErrors);

  const handleOnChangeForm = useCallback(
    form => {
      setForm(form);
      setJsErrors(null);
      if (_.isArray(form.locales) && !_.isEmpty(form.locales)) {
        // check the selected one is within the list
        if (!form.locales.includes(locale)) {
          setLocale(form.locales[0]);
        }
      } else {
        setLocale(null);
      }
    },
    [locale, setForm, setJsErrors, setLocale]
  );

  return (
    <FormEditor
      key={`form_form_${generation}`}
      form={form}
      framework={framework}
      locales={form.locales}
      locale={locale}
      onChange={handleOnChangeForm}
      showFrameworkLogo={showFrameworkLogo}
      onCompleteTranslation={() => {}}
    />
  );
};

export { EditFormPanelContainer };
