import { definePlugin } from '../../code-plug';

import { EditFieldPanelContainer } from './views/edit-field-panel-container';

definePlugin('builder-edit-field', ({ registerView }, params) => {

  registerView(
    'builder-tabs',
    EditFieldPanelContainer,
    {
      tabName: 'Field settings',
      tabKey: 'field',
      ...params
    }
  );
});
