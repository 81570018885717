import React, { lazy } from 'react';
import { Container } from 'rsuite';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { PostHogProvider } from 'posthog-js/react'

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
//import ReactGA from 'react-ga4';

import './App.scss';
// be sure to overwrite
import 'rsuite/dist/rsuite.min.css';
import './rsuite-override.scss';

import './plugins';
import { AuthenticationProvider } from './providers/authentication';
import {
  ImportPage,
  ProjectPage,
  ProjectFormPage,
  DesignerPage,
  DashboardPage,
  LoginPage,
  SettingsPage,
  PaymentPage,
  MessagePage,
  GitHubLandingPage,
  PrivacyPage,
  EmptyPage,
  TermsPage,
  PlaygroundPage,
  PlaygroundConfiguratorPage,
  TestPage
} from './pages';


import { ModalProvider } from './components/modal';
import { CookieBanner } from './components/cookie-banner';

const PlayWrightPage = lazy(() => import('./pages/playwright'));
const BuilderPage = lazy(() => import('./pages/builder-iframe'));
const PublishedFormRSuite5Page = lazy(() => import('./pages/widget/widget-rsuite5'));
const PublishedFormAntdPage = lazy(() => import('./pages/widget/widget-antd'));
const PublishedFormMantinePage = lazy(() => import('./pages/widget/widget-mantine'));
const PublishedFormReactPage = lazy(() => import('./pages/widget/widget-react'));
const PublishedFormBootstrapPage = lazy(() => import('./pages/widget/widget-bootstrap'));
const PublishedFormMUIPage = lazy(() => import('./pages/widget/widget-material-ui'));
const DemoPage = lazy(() => import('./pages/demo'));
const BuilderPlaygroundPage = lazy(() => import('./pages/builder-playground'));


// TODO move away
// TODO refresh token
const httpLink = createHttpLink({
  uri: 'https://dashboard.letsform.dev/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

// DOC: https://www.apollographql.com/docs/react/api/core/ApolloClient
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});


const AnalyticsProvider = ({ children, ...rest }) => {
  return process.env.NODE_ENV !== 'development' ? (
    <PostHogProvider
      apiKey="phc_H7jis35kpEnmnsTqoKNFvzfwam4TVLBPJqh4i9Ryz6u"
      options={{ api_host: 'https://app.posthog.com' }}
      {...rest}
    >{children}</PostHogProvider>
  ) : <>{children}</>
};


function App() {
  const designerPage = <DesignerPage />;
  const settingsPage = <SettingsPage />;
  const playgroundPage = <PlaygroundConfiguratorPage />;

  return (
    <AuthenticationProvider>
      <AnalyticsProvider>
        <ApolloProvider client={client}>
          <ModalProvider>
            <Container>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={designerPage} />
                  <Route path="/w/:formHash/react-rsuite5" element={<PublishedFormRSuite5Page/>} />
                  <Route path="/w/:formHash/react-antd" element={<PublishedFormAntdPage/>} />
                  <Route path="/w/:formHash/react-mantine" element={<PublishedFormMantinePage/>} />
                  <Route path="/w/:formHash/react" element={<PublishedFormReactPage/>} />
                  <Route path="/w/:formHash/react-bootstrap" element={<PublishedFormBootstrapPage/>} />
                  <Route path="/w/:formHash/react-material-ui" element={<PublishedFormMUIPage/>} />
                  <Route path="/f/:formHash" element={designerPage} />
                  <Route path="/p/:formHash" element={<PlaygroundPage/>} />
                  <Route path="/import" element={<ImportPage />} />
                  <Route path="/builder/:builderHash" element={<BuilderPage />} />
                  <Route path="/projects/:projectId" element={<ProjectPage />} />
                  <Route path="/projects/:projectId/forms/:formId" element={<ProjectFormPage />} />
                  <Route path="/projects/:projectId/new" element={<ProjectFormPage />} />
                  <Route path="/payments/:paymentId" element={<PaymentPage />}/>
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/settings" element={settingsPage}/>
                  <Route path="/settings/:section" element={settingsPage}/>
                  <Route path="/message/:code" element={<MessagePage />}/>
                  <Route path="/github" element={<GitHubLandingPage />}/>
                  <Route path="/privacy" element={<PrivacyPage />}/>
                  <Route path="/terms" element={<TermsPage />}/>
                  <Route path="/playground/new" element={playgroundPage} />
                  <Route path="/playground/:shareCode" element={playgroundPage} />
                  <Route path="/test" element={<TestPage />} />
                  <Route path="/demo" element={<DemoPage />} />
                  <Route path="/playwright/:section" element={<PlayWrightPage />} />
                  <Route path="/builder-playground/:builderHash" element={<BuilderPlaygroundPage />} />
                  <Route path="*" element={<EmptyPage />} />
                </Routes>
              </BrowserRouter>
              <CookieBanner />
            </Container>
          </ModalProvider>
        </ApolloProvider>
      </AnalyticsProvider>
    </AuthenticationProvider>
  );
};

export default App;