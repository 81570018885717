import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { gql, useApolloClient } from '@apollo/client';
import { Notification, useToaster } from 'rsuite';

import { ProjectEdit } from '../../../components/lets-form-forms';

const CREATE_PROJECT = gql`
mutation($project: create_projects_input!) {
  project: create_projects_item(data: $project) {
    id,
    name
  }
}`;

const NewProjectModal = ({ onCancel, onSubmit, value, user }) => {
  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const toaster = useToaster();

  const handleSubmit = useCallback(
    async values => {
      try {
        setDisabled(true);
        const savedProject = await client.mutate({
          mutation: CREATE_PROJECT,
          variables: {
            project: {
              ..._.pick(values, 'name', 'organization', 'framework', 'description'),
              organization: user.organization.id
            }
          }
        });
        onSubmit(savedProject.data.project);
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error!" closable>
            <div>
              <b>Something went wrong</b> creating the project <b>{values.name}</b>, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        console.error(`LetsForm error creating project ${values.name}`);
        setDisabled(false);
      }
    },
    [client, onSubmit, toaster, user]
  );

  return (
    <ProjectEdit
      defaultValues={value}
      disabled={disabled}
      onSubmit={handleSubmit}
      onReset={onCancel}
    />
  );
};

export { NewProjectModal };
