import React from 'react';
import { Dropdown } from 'rsuite';

import { CompleteTranslationsGenericButton } from './complete-translations-generic-button';


const CompleteTranslationsMenuItem = () => {
  return (
    <CompleteTranslationsGenericButton>
      {({ onClick }) => (
        <Dropdown.Item onSelect={onClick}>Complete translations</Dropdown.Item>
      )}
    </CompleteTranslationsGenericButton>
  );
};

export { CompleteTranslationsMenuItem };
