import React from 'react';
import Whisper from 'rsuite/Whisper';
import Popover from 'rsuite/Popover';

import { LogoExtended } from '../../../assets/logo-large';

import './lets-form-logo.scss';


const getLetsFormDesignerUrl = () => {
  return process.env.NODE_ENV === 'development' ?
    process.env.REACT_APP_DEVELOPMENT_LETS_FORM_DESIGNER_SITE : process.env.REACT_APP_PRODUCTION_LETS_FORM_DESIGNER_SITE;
};

const LetsFormLogo = () => {
  return (
    <div className="lf-builder-logo">
      <Whisper
        trigger="hover"
        placement="topStart"
        speaker={<Popover
          className="lf-builder-logo-popover"
          visible
          arrow
        >
          Create form builders like this one with <a target="_blank"
            href={getLetsFormDesignerUrl()}
            rel="noreferrer">LetsForm</a>
        </Popover>}
      >
        <a
          target="_blank"
          href={getLetsFormDesignerUrl()}
          rel="noreferrer">
          <LogoExtended width={100} />
        </a>
      </Whisper>
    </div >
  );
};

export { LetsFormLogo };
