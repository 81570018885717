import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Header } from 'rsuite';
import { useSearchParams, useParams } from 'react-router-dom';

import {
  useLocalStorage,
  useThrottledLocalStorage ,
  useTracking,
  useCurrentUser,
  useMobileDetect
} from '../../hooks';
import { ErrorPlaceholder, AlchemicLoader, PhonePlaceholder } from '../../components';
import { Builder } from '../builder';
import { NavigationBar } from '../../layout';
import { FRAMEWORKS } from '../../manifest';
import { fillIds } from '../../components/lets-form';
import { CodePlugProvider } from '../../code-plug';

const isValidForm = form => _.isObject(form) && form.version != null;

const DesignerPage = () => {
  useTracking();
  const [params, setParams] = useSearchParams();
  const { formHash } = useParams();
  const { isMobile } = useMobileDetect();
  const { isAuthenticated } = useCurrentUser();
  const [initialForm, setInitialForm] = useState();
  const [isLoading, setIsLoading] = useState(formHash != null);
  const [error, setError] = useState();
  const [persistedLocale, setPersistedLocale] = useLocalStorage('locale', '');
  const [persistedSize, setPersistedSize] = useLocalStorage('size', 12);
  const [persistedForm, setPersistedForm] = useThrottledLocalStorage('form', '', 5000);
  const [persistedFramework, setPersistedFramework] = useLocalStorage('framework', 'react-rsuite5');

  let framework = initialForm?.framework || persistedFramework;
  if (params.get('framework') && FRAMEWORKS.includes(params.get('framework'))) {
    framework = params.get('framework');
  }

  const handleChange = useCallback(
    form => {
      console.log('[LetsForm] form-autosave ->', form);
      setPersistedForm(form);
    },
    [setPersistedForm]
  );

  const handleFramework = useCallback(
    framework => {
      setPersistedFramework(framework);
    },
    [setPersistedFramework]
  );

  const handleSize = useCallback(
    size => {
      setPersistedSize(size);
    },
    [setPersistedSize]
  );

  const handleLocale = useCallback(
    locale => {
      setPersistedLocale(locale)
    },
    [setPersistedLocale]
  );

  useEffect(
    () => {
      const f = async () => {
        if (formHash) {
          try {
            const res = await fetch(`/shared_forms/${formHash}`);
            const form = await res.json();

            if (isValidForm(form)) {
              setIsLoading(false);
              setInitialForm({
                ...form,
                fields: fillIds(form.fields)
              });
              return;
            }
            setError('Invalid form');
          } catch (e) {
            setError(e);
          }
          setIsLoading(false);
          return;
        } else if (params.get('saveDialog') === 'true') {
          setParams({ saveDialog: 'false' });
          // TODO what is this?
          //handleSave();
        }

        // if no other commands, then try with the persisted forms
        if (isValidForm(persistedForm) && !params.get('emptyForm')) {
          setInitialForm(persistedForm)
        } else {
          setInitialForm(null);
        }
      }
      f();
    },
    [formHash, handleSize, params, persistedForm, setParams]
  );

  if (isMobile) {
    return (
      <PhonePlaceholder />
    );
  }

  return (
    <>
      <Header>
        <NavigationBar />
      </Header>
      <CodePlugProvider
        name="designer"
        plugins={[
          'leave-feedback',
          // Tabs
          'builder-edit-form',
          'builder-edit-field',
          'builder-debug',
          'builder-export',
          'builder-connectors',
          // end Tabs
          'form-builder-export-to-react-code', // button in export panel, menu item in "Form"
          'builder-test-toolbar',
          // initial part of form footer
          'builder-start-empty-form',
          'builder-start-here',
          'builder-menubar',
          // Start menu
          'builder-codesandbox',
          'builder-select-template-core',
          'builder-select-template',
          { plugin: 'builder-locales-selector', persist: false },
          // "Fields" menu
            'builder-frameworks',
            'builder-preview-size',
            { plugin: 'builder-toolbar-separator', parent: 'Fields' },
            'builder-add-field',
            { plugin: 'builder-toolbar-separator', parent: 'Fields' },
            'builder-complete-translations',
          // "Form" menu
            'builder-clear-form',
            { plugin: 'builder-toolbar-separator', parent: 'Form' },
            'builder-import-form',
            { plugin: 'builder-toolbar-separator', parent: 'Form' },
            'builder-share-form',
            //hasFeatureFlag('publishForm') && 'builder-publish-form',
            //hasFeatureFlag('publishForm') && 'builder-playground'
          'builder-save-anonymous'
        ]}
      >
        {(isLoading) && (
          <div style={{textAlign: 'center', paddingTop: '80px' }}>
            <AlchemicLoader/>
          </div>
        )}
        {!error && initialForm !== undefined && isAuthenticated !== undefined && (
          <Builder
            formSchema={initialForm}
            formFramework={framework}
            formSize={initialForm?.size || persistedSize}
            formLocale={persistedLocale}
            features={{
              cancelButton: false,
              saveOptions: false,
              shareForm: false,
              playgroundForm: false,
              connectors: true
            }}
            onChangeFramework={handleFramework}
            onChangeSize={handleSize}
            onChangeLocale={handleLocale}
            onChange={handleChange}
          />
        )}
        {error && (
          <ErrorPlaceholder>
            Error fetching the shared form, perhaps it doesn't exist or it's no longer available
          </ErrorPlaceholder>
        )}
      </CodePlugProvider>
    </>
  );
};

export { DesignerPage };
