import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { PanelGroup, Panel, Loader } from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoRound';

import Manifests from '../../../manifest';
import { ControlCategories } from '../../../types';
import { labelFramework } from '../helpers/framework-label';
import { fetchTemplates } from '../../../helpers';
import { getUnavailableComponents } from '../../../helpers/get-unavailable-components';

import './select-field.scss';
import { ControlCard } from './select-field-card';

const defaultOpenCategories = ['general', 'layout'];

const SelectField = ({
  onSubmit = () => {},
  onChange = () => {},
  disabledComponents,
  framework,
  hideUnavailableComponents
 }) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(null);

  const handleSelect = useCallback(
    async (eventKey) => {
      if (eventKey === 'advanced' && templates === null) {
        setLoading(true);
        const templates = await fetchTemplates({ component: true });
        setLoading(false);
        setTemplates(templates.templates);
      }
    },
    [templates]
  );


  // collect hidden components
  const hiddenComponents = Object.keys(Manifests)
    .reduce(
      (acc, componentName) => Manifests[componentName].hidden ? [...acc, componentName] : acc,
      disabledComponents ?? []
    );
  let manifests = _.chain(Manifests)
    .omit(hiddenComponents)
    .omit(hideUnavailableComponents ? getUnavailableComponents(framework): [])
    .groupBy('category')
    .value();
  const categories = Object.keys(manifests).filter(key => key !== 'undefined');
  // merge advanced templates from the backed
  if (Array.isArray(templates)) {
    const items = templates.map(template => ({
      category: 'advanced',
      template: true,
      templateId: template.id,
      label: template.name,
      name: template.name,
      frameworks: template.frameworks,
      description: template.description
    }));

    manifests = {
      ...manifests,
      advanced: [
        ...manifests.advanced,
        ...items
      ]
    };
  }

  return (
    <div className="lf-control-picker">
      <div className="info">
        <InfoRoundIcon style={{fontSize: '1.2em', marginRight: '5px', marginTop: '-3px' }} color="#336699" />
        Some fields may not be available in <b>{labelFramework(framework)}</b> framework
      </div>
      <PanelGroup
        accordion
        onSelect={handleSelect}
      >
        {categories.map(category => (
          <Panel
            key={category}
            eventKey={category}
            header={ControlCategories[category]}
            defaultExpanded={defaultOpenCategories.includes(category)}
          >
            {(category === 'advanced' && loading) && (
              <div className="loader-container" key="loader">
                <Loader content="Loading components..." />
              </div>
            )}
            {!(category === 'advanced' && loading) && manifests[category].map(control => (
              <ControlCard
                control={control}
                key={control.name}
                onClick={onSubmit}
                framework={framework}
              />
            ))}
          </Panel>
        ))}
      </PanelGroup>
    </div>
  )
};

export { SelectField };
