import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Tag, IconButton, Whisper, Tooltip } from 'rsuite';
import { VscJson } from 'react-icons/vsc';
import { TiDocument } from 'react-icons/ti';

import { useModal } from '../../../components';
import { ShowJson } from '../../../components/show-json-modal';
import FormGenerator, { reduceFields, FRAMEWORKS } from '../../../components/lets-form';
import Manifests from '../../../manifest';
import { JSONEditor } from '../../../components/json';

import { generateFormForField } from '../helpers/generate-form-fields';
import './field-editor.scss';

const DEBOUNCE_CHANGES = 150;
const VALIDATION_FIELDS = {
  validationMinLength: 'minLength',
  validationMin: 'min',
  validationMaxLength: 'maxLength',
  validationMax: 'max',
  validationPattern: 'pattern',
  validationMessage: 'message',
  validationValidate: 'validate'
};
const INVERTED_VALIDATION_FIELDS = _.invert(VALIDATION_FIELDS);


const LETSFORM_ADDITIONAL_COMPONENTS = {
  json: {
    'react-rsuite5': JSONEditor
  }
};

/**
 * prepareJSON
 * Remove id and sub-fields for visualization only
 */
const prepareJSON = json => {
  let result = _.omit(json, 'id', 'leftFields', 'rightFields', 'centerFields', 'fields');

  FRAMEWORKS.forEach(framework => {
    if (_.isEmpty(result.framework)) {
      delete result.framework;
    }
  });

  return JSON.stringify(result, null, 2);
};

const FieldEditor = ({
  field,
  locales,
  locale,
  onChange = () => {},
  framework
}) => {
  const { open, close } = useModal({
    view: ShowJson,
    size: 'sm',
    name: 'showJson',
    align: 'center',
    labelSubmit: 'Close',
    labelCancel: null,
    title: 'JSON code'
  });

  const handlePreviewJSon = useCallback(
    async () => {
      await open({ json: prepareJSON(field) });
      close();
    },
    [open, close, field]
  );

  const manifest = Manifests[field?.component];
  const [form, setForm] = useState(/*generateFormForField(manifest, framework, locales, locale, 'field-editor')*/);
  const [generation, setGeneration] = useState(1);

  const flatField = field ? { ...field, ...field[framework] } : null;
  Object.keys(INVERTED_VALIDATION_FIELDS)
    .forEach(key => {
      flatField[INVERTED_VALIDATION_FIELDS[key]] = flatField.validation ? flatField.validation[key] : undefined;
    });
  delete flatField.validation;

  const Icon = manifest?.icon;

  useEffect(
    () => {
      setForm(generateFormForField(manifest, framework, locales, locale, 'field-editor'))
      // trigger re-generation of the form, per-framework properties are flattened in the field-editor form
      // (i.e. maxWidth per Rsuite5 is the same for AntD), that means that even if the form changes, the internal
      // status don't, keeping the same values, for this reason the values will be mixed, force redesign
      // on change of the framework or locales
      setGeneration(generation => generation + 1);
    },
    [manifest, framework, locales, locale]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    _.debounce(
      values => {
        // collect platform specific fields
        const platformSpecificFields = reduceFields(
          _.isArray(manifest[framework]) ? manifest[framework] : [],
          (field, acc) => {
            if (field.name && !['group', 'two-columns', 'three-columns', 'columns'].includes(field.component)) {
              return [...(acc ?? []), field.name];
            }
            return acc;
          },
          [],
          {
            // avoid arrays, only the array property needs to be moved to the framework specific
            // subkeys, failing this will be a catastrophic error (duplicated values, empty "value", etc)
            'array': false
          }
        );
        console.log('platformSpecificFields', platformSpecificFields)
        // clone obj, keep everyhting but remove validation fields and platform specific fields
        const modifiedField = {
          ...(_.omit(values, Object.keys(VALIDATION_FIELDS), platformSpecificFields))
        };
        // all platform specific keys will be put in a separate keys (i.e., 'react-rsuite5')
        (platformSpecificFields || []).forEach(fieldName => {
          if (!modifiedField[framework]) {
            modifiedField[framework] = {};
          }
          modifiedField[framework][fieldName] = values[fieldName];
          delete values[fieldName];
        })
        // collect all validation fileds and put in validation obhect, excepts "required"
        modifiedField.validation = {};
        Object.keys(VALIDATION_FIELDS)
          .forEach(key => {
            if (values[key]) {
              modifiedField.validation[VALIDATION_FIELDS[key]] = values[key];
              delete modifiedField[key];
            }
          });
        if (_.isEmpty(modifiedField.validation)) {
          delete modifiedField.validation;
        }

        onChange(modifiedField);
      },
      DEBOUNCE_CHANGES
    ),
    [framework, manifest, onChange]
  );

  if (!field) {
    return (
      <div>
        select something
      </div>
    );
  }

  let docLink = manifest.documentation;
  if (_.isEmpty(manifest.documentation)) {
    docLink = (process.env.NODE_ENV === 'development' ?
      process.env.REACT_APP_DEVELOPMENT_LETS_FORM_SITE : process.env.REACT_APP_PRODUCTION_LETS_FORM_SITE) +
      '/components/' + manifest.name;
  }

  return (
    <div className="field-editor">
      <div className="component-info">
        <div className="icon">
          {Icon && <Icon size={24}/>}
        </div>
        <div className="info">
          <span className="name">{manifest.label}</span>
          &nbsp;
          <span className="js">component&nbsp;=&nbsp;</span>
          <Tag size="sm" color="violet">{manifest.name}</Tag>
        </div>
        <Whisper
          trigger="hover"
          placement="left"
          speaker={<Tooltip>Documentation for "{field.component}"</Tooltip>}
        >
          <IconButton
            className="lf-small-button"
            appearance="subtle"
            target="_blank"
            href={docLink}
            icon={<TiDocument />}
          />
        </Whisper>
        <Whisper
          trigger="hover"
          placement="left"
          speaker={<Tooltip>Show JSON code for "{field.name}"</Tooltip>}
        >
          <IconButton
            className="lf-small-button"
            appearance="subtle"
            icon={<VscJson />}
            onClick={handlePreviewJSon}
          />
        </Whisper>
      </div>
      {form && (
        <FormGenerator
          key={`${field.id}_${generation}`}
          form={form}
          framework="react-rsuite5"
          hideToolbar={true}
          defaultValues={flatField}
          onChange={handleChange}
          components={LETSFORM_ADDITIONAL_COMPONENTS}
        />
      )}
    </div>
  );
}

export { FieldEditor };
