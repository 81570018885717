import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

import './demo-tag.scss';

const DemoTag = () => {
  return (
    <div className="lf-demo-tag">
      <Whisper
        trigger="hover"
        speaker={<Tooltip>
          This is a preview of how the LetsForm Builder will appear once embedded
          in the destination site, it's not storing the form anywhere, every changes will be lost
          the moment you leave this page
        </Tooltip>}
        placement="left"
      >
        <div className="inner">PREVIEW</div>
      </Whisper>
    </div>
  );
};

export { DemoTag };
