import React, { useCallback } from 'react';
import { Button, Whisper, Tooltip } from 'rsuite';
import _ from 'lodash';

import { useModal } from '../modal';
import { useTracking } from '../../hooks';
import { OmniEditorModal } from './omni-editor-modal';

import './omni-editor.scss';

const LABELS = {
  'validator': {
    labelCancel: 'Cancel',
    labelSubmit: 'Save validator',
    title: 'Custom JS validator',
    buttonLabel: 'Custom JS validator',
    buttonTooltip: 'Add a custom JavaScript validate function',
    buttonTooltipEdit: 'Edit custom JavaScript validate function',
    event: 'form.js-validation'
  },
  'transformerOnChange': {
    labelCancel: 'Cancel',
    labelSubmit: 'Save JS snippet',
    title: 'JS Snippet',
    buttonLabel: 'JS Snippet',
    buttonTooltip: 'Add a custom JavaScript snippet executed at first render and every time the field changes',
    buttonTooltipEdit: 'Edit custom JavaScript snmippet',
    event: 'form.js-snippet'
  },
  'css': {
    labelCancel: 'Cancel',
    labelSubmit: 'Save Stylesheet',
    title: 'Form Cascading Style Sheet',
    buttonLabel: 'Form Style (CSS)',
    buttonTooltip: 'Add a custom stylesheet for this form',
    buttonTooltipEdit: 'Edit custom stylesheet for this form',
    event: 'form.css'
  }
};

const OmniEditorField = ({ value, onChange, editorType = 'validator' }) => {
  const { sendEvent } = useTracking();
  const { labelCancel, labelSubmit, title, buttonLabel, buttonTooltip, buttonTooltipEdit, event } = LABELS[editorType];

  const { open: openValidator, close: closeValidator } = useModal({
    view: OmniEditorModal,
    size: 'lg',
    name: `edit-${editorType}`,
    className: 'lf-modal-js-editor',
    align: 'center',
    labelCancel,
    labelSubmit,
    title
  });

  const handleValidator = useCallback(
    async () => {
      sendEvent(event);
      const validator = await openValidator(
        value ?? '',
        { editorType }
      );
      closeValidator();
      // if value not null (= user hits cancel), then propagate
      if (validator != null) {
        onChange(validator);
      }
    },
    [sendEvent, event, openValidator, value, editorType, closeValidator, onChange]
  );

  return (
    <div className="lf-omni-editor">
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>{!_.isEmpty(value) ? buttonTooltipEdit : buttonTooltip}</Tooltip>}
      >
        <Button
          appearance="primary"
          size="sm"
          onClick={handleValidator}
        >
          {buttonLabel}
        </Button>
      </Whisper>
      {!_.isEmpty(value) && (
        <span className="size">
          <b>{value.length}</b> <span className="unit">bytes</span>
        </span>
      )}
    </div>
  );
};

export { OmniEditorField };
