import React from 'react';

import { ButtonHint } from '../../../components';
import { useFormBuilderStore } from '../../../pages/builder/state';

import { CompleteTranslationsGenericButton } from './complete-translations-generic-button';

const CompleteTranslationsSidebarButton = () => {
  const form = useFormBuilderStore(state => state.form);

  if (Array.isArray(form.locales) && form.locales.length !== 0) {
    return (
      <div style={{ paddingTop: '10px' }}>
        <CompleteTranslationsGenericButton>
          {({ onClick }) => (
            <ButtonHint
              appearance="primary"
              size="sm"
              onClick={onClick}
              hint="Complete automatically the missing translations"
            >Complete translations</ButtonHint>
          )}
        </CompleteTranslationsGenericButton>
      </div>
    );
  }

  return null;
};

export { CompleteTranslationsSidebarButton };
