import React, { useCallback, useState } from 'react';
import { useQuery, gql, useApolloClient } from '@apollo/client';

import { useCurrentUser, useNotification } from '../../../hooks';
import { DashboardSidebarList } from '../../../components/dashboard-sidebar-list';

const GET_SHARED_FORMS = gql`
query($userId: String!) {
  shared_forms(
    filter: {
      user_created: {
        id: {
          _eq: $userId
        }
      }
    }
  ) {
    id,
    name,
    shareCode,
    date_created
  }
}`;

const DELETE_SHARED_FORM = gql`
mutation($id: ID!) {
  delete_shared_forms_item(id: $id) {
    id
  }
}`;

const LETS_FORM_DESIGNER_URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:3000' : 'https://designer.letsform.dev';
const getSharedFormLink = shareCode => `${LETS_FORM_DESIGNER_URL}/f/${shareCode}`;

const SharedFormsSidebar = () => {
  const { user } = useCurrentUser({ redirectToLogin: false });
  const notify = useNotification();
  const client = useApolloClient();
  const [disabled, setDisabled] = useState(false);
  const { data, loading, refetch } = useQuery(GET_SHARED_FORMS, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      userId: user?.id
    }
  });

  const handleDeleteLink = useCallback(
    async (sharedForm) => {
      if (!window.confirm(`Delete shared form "${sharedForm.name}"`)) {
        return;
      }
      try {
        setDisabled(true);
        await client.mutate({
          mutation: DELETE_SHARED_FORM,
          variables: {
            id: sharedForm.id
          }
        });
        setDisabled(false);
        notify.success('Shared form', 'The shared form was deleted successfully!');
        refetch();
      } catch(e) {
        notify.error('Shared form', e);
        setDisabled(false);
      }
    },
    [client, notify, refetch]
  );

  const handleButton = useCallback(
    (button, sharedForm) => {
      if (button === 'copy') {
        navigator.clipboard.writeText(getSharedFormLink(sharedForm.shareCode));
        notify.success(
          'Link copied!',
          <div>Shared form link was succesfully copied on the clipboard</div>
        );
      } else if (button === 'delete') {
        handleDeleteLink(sharedForm);
      }

    },
    [handleDeleteLink, notify]
  );

  return (
    <DashboardSidebarList
      title="Shared forms"
      description="A shared form is a snapshot of one your saved form that you can share with your friends or collegues"
      data={data && data.shared_forms ? data.shared_forms : []}
      buttons={[
        { button: 'delete', label: 'Delete' },
        { button: 'copy', label: 'Copy link' }
      ]}
      onButton={handleButton}
      onClickRow={data => {
        window.location = getSharedFormLink(data.shareCode);
      }}
      loading={loading}
      disabled={disabled}
      ComponentRow={({ data }) => <span>{data.name}</span>}
      emptyPlaceholder={
        <>
          There are no shared forms, to create one: open a saved form then
          {' '}
          <em>Form</em> &rarr; <em>Shared form</em>
        </>
      }
    />
  );
};

export { SharedFormsSidebar };
