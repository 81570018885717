import React from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import './test-tag.scss';

const TestTag = ({ hideEditModesLabel = false }) => {
  const editMode = useFormBuilderStore(state => state.editMode);
  const plaintextMode = useFormBuilderStore(state => state.plaintextMode);

  if (hideEditModesLabel) {
    return <></>;
  };

  return (
    <div className="lf-test-tag">
      {editMode && !plaintextMode && (
        <div className="label-edit-mode">Edit mode</div>
      )}
      {editMode && plaintextMode && (
        <div className="label-plaintext-mode">Plaintext mode</div>
      )}
      {!editMode && (
        <div className="label-preview-mode">Preview mode</div>
      )}
    </div>
  );
};

export { TestTag };