import React, { useCallback, useState } from 'react';
import { Dropdown } from 'rsuite';

import { useListenTo, useEvent } from '../../../code-plug';
import { SelectTemplate } from './select-template';

const GenericSelectTemplate = ({ disabled }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const callInstallTemplate = useEvent('installTemplate');

  const publishEventAttrs = useListenTo({
    'selectTemplate': () => {
      setShowDrawer(true);
    }
  });

  const handleGallery = useCallback(
    (e) => {
      e && e.preventDefault();
      setShowDrawer(true)
    },
    []
  );

  const handleCancel = useCallback(
    () => setShowDrawer(false),
    []
  );

  const handleImportTemplate = useCallback(
    async (template, id, target, subtarget) => {
      setShowDrawer(false);
      callInstallTemplate({ template, id, target, subtarget });
    },
    [callInstallTemplate]
  );

  return (
    <span {...publishEventAttrs}>
      {showDrawer && (
        <SelectTemplate
          onCancel={handleCancel}
          onSelect={handleImportTemplate}
        />
      )}
        <Dropdown
          title="Form templates"
          className="btn-forms-template"
          menuStyle={{ backgroundColor: '#ffffff' }}
          disabled={disabled}
          onClick={handleGallery}
        />
      </span>
  );
};

export { GenericSelectTemplate };
