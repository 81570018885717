import { definePlugin } from '../../code-plug';

import { InstallTemplateCore } from './views/install-template-core';

definePlugin('builder-select-template-core', ({ registerView }) => {

  // register toolbar button to open templates
  registerView(
    'form-builder-canvas',
    InstallTemplateCore,
    {
      key: 'form-builder-select-template-core',
      event: 'installTemplate'
    }
  );
});
