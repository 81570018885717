import { useCallback } from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { useTracking } from '../../../hooks';
import { useModal, CreateHelpButton } from '../../../components';

import { getGroceryList } from '../helpers/get-grocery-list';
import { reconcileFormWithGroceryList } from '../helpers/reconcile-form-with-grocery-list';

import { CompleteTranslations } from './complete-translations';

const CompleteTranslationsGenericButton = ({ children }) => {
  const tracking = useTracking();
  const form = useFormBuilderStore(state => state.form);
  const setForm = useFormBuilderStore(state => state.setForm);
  const setField = useFormBuilderStore(state => state.setField);
  const setJsErrors = useFormBuilderStore(state => state.setJsErrors);

  const { open: openTranslation, close: closeTranslation } = useModal({
    view: CompleteTranslations,
    size: 'lg',
    name: 'import_form',
    align: 'center',
    labelSubmit: 'Save translations',
    labelCancel: 'Cancel',
    title: 'Translations auto-complete',
    custom: CreateHelpButton('https://letsform.dev/lets-form-designer/automatic-translations'),
    enableSubmit: value => {
      const anyTranslation =  (value.groceryList ?? []).some(item => item.translation != null);
      return !value.translating && anyTranslation;
    }
  });

  const handleCompleteTranslations = useCallback(
    async () => {
      tracking.sendEvent('form.translations');
      const value = await openTranslation({
        loading: false,
        groceryList: getGroceryList(form)
      });
      closeTranslation();

      if (value && value.groceryList) {
        //handleOnChangeForm(reconcileFormWithGroceryList(form, value.groceryList));
        setForm(reconcileFormWithGroceryList(form, value.groceryList));
        setJsErrors(null);
        setField(null);
      }
    },
    [tracking, openTranslation, form, setForm, closeTranslation, setJsErrors, setField]
  );

  return children({ onClick: handleCompleteTranslations });
};

export { CompleteTranslationsGenericButton };
