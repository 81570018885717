import { useCallback, useEffect } from 'react';
import _ from 'lodash';

const usePostMessage = (params) => {
  const {
    ns,
    onChange = () => {}
  } = params;

  const postMessage = useCallback(
    obj => {
      if (window.parent && window.parent.postMessage) {
        window.parent.postMessage({ ns, ...obj }, '*');
      } else {
        console.log('warning post message not supported');
      }
    },
    [ns]
  );

  useEffect(
    () => {
      const processMessage = (event) => {
        const msg = event.data;
        if (msg.ns === ns) {
          onChange(_.omit(msg, 'ns'));
        }
      };
      window.addEventListener('message', processMessage);
      return () => window.removeEventListener('message', processMessage);
    },
    [ns, onChange]
  );

  return {
    postMessage
  };
};

export { usePostMessage };
