import { definePlugin } from '../../code-plug';

import { ClearFormMenuItem } from './views/clear-form-menu-item';

definePlugin('builder-clear-form', ({ registerView }) => {

  // register the toolbar menu item to add a field
  registerView(
    'form-builder-toolbar',
    ClearFormMenuItem,
    {
      key: 'form-builder-clear-form-item',
      parent: 'Form'
    }
  );

});
