/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd'

import { isChildrenOf } from '../../../../components/lets-form';

import './group-wrapper.scss';

const GroupWrapper = ({
  children,
  field,
  fields,
  onEdit,
  onDelete,
  selected = false,
  onAddField = () => {},
  onMove = () => {},
  className,
  index
}) => {

  const [hover, setHover] = useState(false);

  const ref = useRef(null)
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ['card', 'component'],
    collect(monitor) {
      const item = monitor.getItem();
      if (
        !ref.current ||
        item?.fieldId === field.id || // don't drop on youself
        isChildrenOf(field?.id, item?.id, fields) // don't drop inside itself
      ) {
        return {};
      }

      return {
        isOver: monitor.isOver() && monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item, monitor) {
      console.log('droppo gruppo', item, 'droppato?', monitor.didDrop())
      if (item.dropType === 'field') {
        // dragging a field from the form
        if (!ref.current ||
          monitor.didDrop() || // exit if already dropped in an inner element
          item?.fieldId === field.id || // don't drop on youself
          isChildrenOf(field?.id, item?.id, fields) // don't drop inside itself
        ) {
          return
        }
        onMove(item.id, field.id);
      } else if (item.dropType === 'component') {
        if (!ref.current ||
          monitor.didDrop() // exit if already dropped in an inner element
        ) {
          console.log('esco da drop group')
          return;
        }

        onAddField(item.componentName, field.id);
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return {
        id: field.id,
        fieldId: field.id,
        index: index,
        dropType: 'field'
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      fieldId: field.id
    })
  });

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref));

  const handleMouseEnter = useCallback(
    e => {
      setHover(true);
    },
    []
  );
  const handleMouseLeave = useCallback(
    e => {
      setHover(false);
    },
    []
  );
  const handleEdit = useCallback(
    event => {
      event.preventDefault();
      onEdit(field);
    },
    [field, onEdit]
  );
  const handleDelete = useCallback(
    event => {
      event.preventDefault();
      onDelete(field);
    },
    [field, onDelete]
  );

  return (
    <div className="lf-group-super-wrapper">
      <div
        className={classNames('lf-group-wrapper', className, { selected, hover })}
        ref={ref}
        data-handler-id={handlerId}
        style={{ opacity }}
      >
        {field.hidden === true && (
          <div className="hidden">
            hidden
          </div>
        )}
        <div
          className="group-toolbar"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="info">
            <span className="name">{field.name}</span>
            {' / '}
            <span className="component-name">{field.component}</span>
          </div>
          <div className="buttons">
            <a
              href="#"
              onClick={handleEdit}
            >edit</a>
            &nbsp;<span className="separator">&bull;</span>&nbsp;
            <a
              href="#"
              onClick={handleDelete}
            >delete</a>
          </div>
        </div>
        <div
          className="component"
          style={{ opacity: field.hidden === true ? 0.3 : 1 }}
        >
          {children}
        </div>
      </div>
      {isOver && (<div className="hover-placelhoder" />)}
    </div>
  );
};

export { GroupWrapper };
