import { definePlugin } from '../../code-plug';

import { TestToolbarContainer } from './views/test-toolbar-container';
import { TestTag } from './views/test-tag';

/**
 * builder-test-toolbar
 * Test toolbar in the canvas to switch form modes
 *
 * Params
 * top: margin top in pixel
 * buttons: array of enabled buttons: reload, test-data, edit-modes, save
 * hideEditModesLabel: hide the label showing the modes
 */
definePlugin('builder-test-toolbar', ({ registerView }, params) => {

  registerView(
    'form-builder-canvas',
    TestToolbarContainer,
    { ...params }
  );

  registerView(
    'form-builder-preview',
    TestTag,
    { ...params }
  )
});
