import React from 'react';
import classNames from 'classnames';
import { Whisper, Tooltip, Button } from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoRound';

import './dashboard-label.scss';

const DasboardLabel = ({
  children,
  description,
  className,
  button,
  onButton,
  disabled = false
}) => {
  return (
    <div className={classNames('lf-label', className)}>
      {children}
      {description && (
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{description}</Tooltip>}
        >
          <InfoRoundIcon />
        </Whisper>
      )}
      {button && (
        <Button
          appearance="ghost"
          className="btn-create"
          size="xs"
          disabled={disabled}
          onClick={onButton}
        >{button}</Button>
      )}
    </div>
  );
};

export { DasboardLabel };
