/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'lodash';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { ArrowDash } from '../../../assets/icons';

import './start-empty-form.scss';

const StartEmptyForm = () => {
  const form = useFormBuilderStore(state => state.form);

  return (
    <div className="lf-start-here-dnd">
      {_.isEmpty(form.fields) && (
        <div className="start-here-dnd">
          <div className="drop-here">Drop components here</div>
          Click on the button below to add a field to your empty form or drag and drop elements from the sidebar.
          <ArrowDash />
        </div>
      )}
    </div>
  );
};

export { StartEmptyForm };