import { definePlugin } from '../../code-plug';

import { EditFormPanelContainer } from './views/edit-form-panel-container';

definePlugin('builder-edit-form', ({ registerView }, params) => {

  registerView(
    'builder-tabs',
    EditFormPanelContainer,
    {
      tabName: 'Form settings',
      tabKey: 'form',
      showFrameworkLogo: true,
      ...params
    }
  );
});
