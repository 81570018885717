import React, { useState, useEffect, useMemo } from 'react';
import { createTheme, MantineProvider } from '@mantine/core';
import CustomProvider from 'rsuite/CustomProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfigProvider } from 'antd';
import _ from 'lodash';

import '@mantine/dates/styles.css';

import { AlchemicLoader } from '../alchemic-loader';
import { lfLog, lfError } from '../lets-form/helpers/lf-log';
import { reduceFields } from '../lets-form/helpers';
import Manifests from '../../manifest';

import { ViewErrorBoundary } from '../error-boundary';

/**
 * createLoader
 * Create a promise genrator for module loader which returns the module and its name,
 * in order to be stored later in the form context
 * @param {*} name Name of the module
 * @param {*} func The module loader
 */
const createLoader = (name, func) => {
  return params => {
    return new Promise((resolve, reject) => {
      (func(params) || Promise.resolve({}))
        .then(
          (module) => {
            lfLog(`Loaded module "${name}"`)
            resolve({ module, name });
          },
          reject
        );
    });
  }
};

const collectAllImports = (form, framework, locale) => {
  if (!form || !locale) {
    return [];
  }
  // collect all used components
  const components = _.uniq(reduceFields(
    form.fields,
    (field, acc) => [...acc, field.component],
    []
  ));
  // chain if there are imports for the specific component / framework
  return _.chain(components)
    .map(component => {
      if (Manifests[component] &&
        Manifests[component].dependencies &&
        Manifests[component].dependencies[framework] &&
        Manifests[component].dependencies[framework].imports
      ) {
        return Object.keys(Manifests[component].dependencies[framework].imports)
          //.map(moduleName => Manifests[component].dependencies[framework].imports[moduleName]);
          .map(moduleName => createLoader(moduleName, Manifests[component].dependencies[framework].imports[moduleName]));
      }
      return null;
    })
    .compact()
    .flatten()
    .map(imp => _.isFunction(imp) ? imp({ locale }) : null)
    .compact()
    .value()
};

/**
 * EnsureFrameworkProvider
 * Ensure a framework has its mandatory provider (if present)
 * Also check that all import are imported successfuly before rendering the form
 * @returns
 */
const EnsureFrameworkProvider = ({ framework, form, locale, children, onError }) => {
  const [error, setError] = useState(null);
  const imports = useMemo(
    () => collectAllImports(form, framework, locale),
    [form, framework, locale]
  );

  const [loading, setLoading] = useState(imports.length !== 0);
  const [modules, setModules] = useState({});

  useEffect(
    () => {
      if (imports.length) {
        setLoading(true);
        lfLog(`Loading ${imports.length} modules for this form`);
        Promise.all(imports)
          .then(allImported => {
            // store the loaded modules, in order to be used later (i.e. the locale file)
            setModules(allImported
              .reduce(
                (acc, { module, name}) => ({ ...acc, [name]: module }),
                {}
              )
            );
            setLoading(false)
          })
          .catch(e => {
            lfError(`Error loading module for ${framework}`, e);
            setError(e);
          });
      }
    },
    [imports, framework, onError]
  );

  if (error) {
    return (
      <div>
        <ViewErrorBoundary error={error}/>
      </div>
    )
  }

  if (loading) {
    return (
      <div>
        <AlchemicLoader margin={80}/>
      </div>
    );
  }

  if (framework === 'react-mantine') {
    const theme = createTheme({
      /** Put your mantine theme override here */
    });
    return (
      <MantineProvider theme={theme}>
        {children}
      </MantineProvider>
    );
  }

  if (framework === 'react-rsuite5') {
    return (
      <CustomProvider locale={modules.locale?.default}>
        {children}
      </CustomProvider>
    );
  }

  if (framework === 'react-antd') {
    return (
      <ConfigProvider
        theme={{
          token: {
            motion: false,
          }
        }}
        locale={modules.locale?.default}>
        {children}
      </ConfigProvider>
    );
  }

  if (framework === 'react-material-ui') {
    const localeCode = locale && _.isString(locale) ? locale.split('-')[0] : 'en';
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeCode}>
        {children}
      </LocalizationProvider>
    );
  }

  return (
    <div>
      {children}
    </div>
  );
};

export { EnsureFrameworkProvider };
