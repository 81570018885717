import { definePlugin } from '../../code-plug';

import { PreviewSizeMenuItem } from './views/preview-size-menu-item';

definePlugin('builder-preview-size', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    PreviewSizeMenuItem,
    {
      key: 'form-builder-select-template',
      parent: 'Fields'
    }
  );
});
