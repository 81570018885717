import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { FlexboxGrid } from 'rsuite';

import { JSONEditor } from '../../components/json-editor';

import { TransformerHelp, ValidateHelp, CSSHelp } from './components';
import './js-editor.scss';

const OmniEditorModal = ({
  onChange = () => {},
  value,
  editorType
}) => {
  const [jsCode, setJSCode] = useState(
    !_.isEmpty(value) ? value : ''
  );

  let mode = 'javascript';
  if (editorType === 'css') {
    mode = 'css';
  }

  console.log('mode', editorType, mode)

  const handleChange = useCallback(
    jsCode => {
      setJSCode(jsCode);
      onChange(_.trim(jsCode));
    },
    [onChange]
  );

  return (
    <div className="lf-js-editor">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <JSONEditor
            value={jsCode}
            mode={mode}
            height="400px"
            onChange={handleChange}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} className="help-section">
          {editorType === 'validator' && <ValidateHelp />}
          {(editorType === 'transformerOnRender' || editorType === 'transformerOnChange')  && (
            <TransformerHelp editorType={editorType}/>
          )}
          {editorType === 'css' && <CSSHelp />}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export { OmniEditorModal };
