import _ from 'lodash';

import { fillIds } from '../components/lets-form/helpers/fill-ids';

const DASHBOARD_URL = process.env.NODE_ENV === 'development' && false ?
  process.env.REACT_APP_DEVELOPMENT_DASHBOARD_URL : process.env.REACT_APP_DASHBOARD_URL;

const fetchTemplates = async({ component = null} = {}) => {
  let filter;

  if (_.isBoolean(component)) {
    if (component) {
      filter = `(filter: { isComponent: { _eq: ${component} }})`;
    } else {
      filter = `(filter: {_or: [{ isComponent: { _eq: false }}, { isComponent: { _null: true }}] })`;
    }
  }

  const request = await fetch(`${DASHBOARD_URL}?v=` + Math.random(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        query {
          categories: template_categories(sort: "name") {
            id,
            name
          }
          templates${filter ?? ''} {
            id,
            name,
            description,
            locales,
            frameworks,
            version,
            category {
              id
            }
            user_created {
              first_name,
              last_name
            },
            previews {
              id,
              directus_files_id {
                id,
                title,
                filename_download
              }
            }
          }
        }
      `
    })
  });

  const result = await request.json();

  return result.data;
};

/**
 * fetchTemplate
 * Fetch a template given the id. It also parses the json template and fill with new ids to avoid id clashing
 * in the editor
 * @param {Int} id Template id
 */
const fetchTemplate = async id => {
  const request = await fetch(`${DASHBOARD_URL}?v=` + Math.random(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query($id: ID!) {
          template: templates_by_id(id: $id) {
            id,
            name,
            description,
            template,
            installForm,
            user_created {
              first_name,
              last_name
            }
          }
        }
      `,
      variables: {
        id
      }
    })
  });

  const result = await request.json();
  try {
    result.data.template.jsonTemplate = JSON.parse(result.data.template.template);
    result.data.template.jsonTemplate = {
      ...result.data.template.jsonTemplate,
      fields: fillIds(result.data.template.jsonTemplate.fields)
    };
  } catch(e) {
    // do nothing
  }

  return result.data.template;
};

export { fetchTemplates, fetchTemplate };
