import React, { useCallback, startTransition } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '../../../hooks';
import { DashboardSidebarList } from '../../../components/dashboard-sidebar-list';
import { FrameworkIcon } from '../../../components/framework-icon';

const GET_BUILDERS = gql`
query($organizationId: GraphQLStringOrFloat) {
  builders(
    limit: 10,
    filter: {
      organizationId: {
        id: {
          _eq: $organizationId
        }
      }
    }
  ) {
    id,
    name,
    builderHash,
    framework
  }
}`;

const FormBuilderRow = ({ data }) => {
  return (
    <span>
      {data.name} <FrameworkIcon framework={data.framework} size={16}/>
    </span>
  );
};

const FormBuildersSidebar = () => {
  const { user } = useCurrentUser({ redirectToLogin: false });
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_BUILDERS, {
    skip: !user,
    variables: {
      organizationId: user?.organization?.id
    }
  });

  const handleButton = useCallback(
    (button, builder) => {
      startTransition(() => {
        if (button === 'view') {
          navigate(`/builder-playground/${builder.builderHash}`);
        }
      });
    },
    [navigate]
  );

  const handleView = useCallback(
    data => {
      startTransition(() => navigate(`/builder-playground/${data.builderHash}`));
    },
    [navigate]
  );

  const handleCreate = useCallback(
    () => {
      startTransition(() => navigate('/builder-playground/new'));
    },
    [navigate]
  );

  return (
    <DashboardSidebarList
      title="LetsForm Builders"
      description="A LetsForm Builder allows to embed a form editor anywhere as a WebComponent"
      data={data && data.builders ? data.builders : []}
      createButton="Create builder"
      onCreateButton={handleCreate}
      buttons={[
        { button: 'view', label: 'View' }
      ]}
      onButton={handleButton}
      onClickRow={handleView}
      loading={loading}
      ComponentRow={FormBuilderRow}
      emptyPlaceholder={
        <>
          There are no LetsForm builders, click the button above to create one
        </>
      }
    />
  );
};

export { FormBuildersSidebar };
