import { definePlugin } from '../../code-plug';

import { BuilderToolbar } from './views/builder-toolbar';

definePlugin('builder-menubar', ({ registerView }, params) => {

  registerView(
    'form-builder-topbar',
    BuilderToolbar
  );
});
